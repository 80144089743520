import style from './index.module.scss';

import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Support() {
    const { t } = useTranslation();

    return <section className={style.container} id='contact'>
        <h2>{t('support_section.title')}</h2>
        <p>{t('support_section_subtitle')}</p>

        <span className={style.support_bar}>
            <Link to={'/support'}>
                <span className={style.support_tile}>
                    <span className={style.icon_chat}/>
                    <h3 className={style.support_header}>{t('support_section_contact_form')}</h3>
                    <p className={style.support_description}>{t('support_section_contact_form_description')}</p>
                </span>
            </Link>

            <Link to={'/guide'}>
                <span className={style.support_tile}>
                    <span className={style.icon_faq}/>
                    <h3 className={style.support_header}>FAQ</h3>
                    <p className={style.support_description}>{t('support_section_faq_description')}</p>
                </span>
            </Link>

            <a href={'mailto:info@influspace.com'}>
                <span className={style.support_tile}>
                    <span className={style.icon_letter}/>
                    <h3 className={style.support_header}>{t('support_section_contact_email')}</h3>
                    <p className={style.support_description}>{t('support_section_contact_email_description')}</p>
                </span>
            </a>
        </span>

    </section>
}